.projectImages img:last-child {
    display: none;
}

.projectImages:hover img:last-child {
    display: block;
}

.projectImages:hover img:first-child {
    display: none;
}

.projectTitles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: #ffffff;
    text-shadow: 2px 2px 4px #000000;
}

.projectImages:hover h2 {
    display: none;
}